/* Provide sufficient contrast against white background */


.pdfWrapper{
  position: relative;
  overflow: hidden;
  width: 100%;
  /*padding-top: 56.25%;*/
}
.pdfWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  border: 0;
}
canvas {
  width: 100%;
  height: 100px; 
  display: block;
  border-bottom: 1px solid #000;
}
@media (orientation: portrait) {
  .pdfWrapper{
    padding-top: 100%;
  }
}
